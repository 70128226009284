<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
        <h5 class="text-center">Please enter the service personnel' information.</h5>
        <div class="row d-flex justify-content-center">
            <div class="col-md-11">
                <card title="Service Person">
                    <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="firstName"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="firstName"
                                            :error="failed ? 'The First Name field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="First Name (required)"
                                            label="First Name"
                                            v-model="ServicePerson.firstName"
                                            ddon-left-icon="nc-icon nc-single-02">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="lastName"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="lastName"
                                            :error="failed ? 'The Last Name field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="Last Name (required)"
                                            label="Last Name"
                                            v-model="ServicePerson.lastName"
                                            ddon-left-icon="nc-icon nc-single-02">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <!-- 
                                    <ValidationProvider
                                        name="email"
                                        rules="required|email"
                                        v-slot="{ passed, failed }"
                                            <fg-input name="email"
                                                :error="failed ? 'The Email field is required': null"
                                                :hasSuccess="passed"
                                                placeholder="Email (required)"
                                                label="Email"
                                                v-model="contact.email">
                                            </fg-input>
                                    </ValidationProvider>
                                    -->
                                    <fg-input name="email"
                                        placeholder="Email (required)"
                                        label="Email"
                                        v-model="ServicePerson.email">
                                    </fg-input>
                            </div>
                            <div class="col-md-6">
                                <fg-input
                                        name="customer phone"
                                        label="Phone Number"
                                        >
                                    <vue-tel-input autocomplete="on"
                                                    v-model="ServicePerson.phoneNumber"
                                                    mode="international"
                                                    :autoDefaultCountry='false'>
                                    </vue-tel-input>
                                </fg-input>
                            </div>
                        </div>
                    </div>
                </card>  <!-- end card -->
            </div>
        </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex, digits } from "vee-validate/dist/rules";
  import Vue from 'vue'
  import VueTelInput from 'vue-tel-input'
  import 'vue-tel-input/dist/vue-tel-input.css'

  Vue.use(VueTelInput)


  extend("required", required);
  extend("email", email);
  extend("regex", regex);
  extend("digits", digits);

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data () {
      return {
        ServicePerson: {
            firstName:'',
            lastName:'',
            phoneNumber:'',
            email:''
        },
        numContacts: 1
      }
    },
    props: {
        currentServicePersonnel: Object
    },
    mounted(){
        console.log("mounted service personnel", this.currentServicePersonnel)
        if(this.currentServicePersonnel != null){
            this.ServicePerson = this.currentServicePersonnel
            this.numContacts = 1
            console.log(this.currentServicePersonnel)
        }
    },
    watch: {
        currentServicePersonnel: {
            handler(){
                console.log("watch service personnel")
                if(this.currentServicePersonnel != null){
                    this.ServicePerson= this.currentServicePersonnel
                    this.numContacts = 1
                    console.log(this.currentServicePersonnel)
                }
                else{
                    this.ServicePerson = {
                        firstName:'',
                        lastName:'',
                        phoneNumber:'',
                        email:'',
                        contactType: '',
                        venueID:'',
                    }
                }
            },
            deep: true
        }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
            this.$emit('on-validated', res)
            console.log("validate service personnel")
            this.$emit("on-LoopLastStep")
            if(res == true){
                console.log(true)
                this.$emit("on-personnelSubmitting", this.ServicePerson)
            }
            return res
        })
      }
    },
  }
</script>
<style>
.custom-button {
    font-family: Arial;
    float: right;
    font-size: 1.005rem;
    text-align: right;
    margin: 0.005px;
    cursor: pointer;
}
.custom-icon {
   font-size: 1rem;
   font-weight: bold;
   text-align: right;
   margin: 1;
}
</style>
