var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Please enter the customer's general information.")]),_c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"col-md-11"},[_c('card',{attrs:{"title":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"Name","error":failed ? 'The Name field is required': null,"hasSuccess":passed,"placeholder":"Name (required)","label":"Name","ddon-left-icon":"nc-icon nc-single-02"},model:{value:(_vm.customerGeneralInfo.name),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "name", $$v)},expression:"customerGeneralInfo.name"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"city","error":failed ? 'The City field is required': null,"hasSuccess":passed,"placeholder":"City (required)","label":"City"},model:{value:(_vm.customerGeneralInfo.city),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "city", $$v)},expression:"customerGeneralInfo.city"}})]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"state","error":failed ? 'The State field is required': null,"hasSuccess":passed,"placeholder":"State (required)","label":"State"},model:{value:(_vm.customerGeneralInfo.state),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "state", $$v)},expression:"customerGeneralInfo.state"}})]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"zipCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"zipCode","error":failed ? 'The Zip Code field is required': null,"hasSuccess":passed,"placeholder":"Zip Code (required)","label":"Zip Code"},model:{value:(_vm.customerGeneralInfo.zipCode),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "zipCode", $$v)},expression:"customerGeneralInfo.zipCode"}})]}}])})],1)]),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"address","error":failed ? 'The Address field is required': null,"hasSuccess":passed,"placeholder":"Address (required)","label":"Address"},model:{value:(_vm.customerGeneralInfo.address),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "address", $$v)},expression:"customerGeneralInfo.address"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"country","error":failed ? 'The Country field is required': null,"hasSuccess":passed,"placeholder":"Country (required)","label":"Country"},model:{value:(_vm.customerGeneralInfo.country),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "country", $$v)},expression:"customerGeneralInfo.country"}})]}}])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"name":"email","placeholder":"Email (optional)","label":"Email"},model:{value:(_vm.customerGeneralInfo.email),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "email", $$v)},expression:"customerGeneralInfo.email"}})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"customer status","error":failed ? 'The Customer status field is required': null,"hasSuccess":passed,"label":"Customer Status"}},[_c('el-select',{staticClass:"select-primary",attrs:{"filterable":"","clearable":""},model:{value:(_vm.customerGeneralInfo.status),callback:function ($$v) {_vm.$set(_vm.customerGeneralInfo, "status", $$v)},expression:"customerGeneralInfo.status"}},_vm._l((_vm.statusList),function(option){return _c('el-option',{key:option,staticClass:"select-primary",attrs:{"value":option,"label":option}})}),1)],1)]}}])})],1)])],1)])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }