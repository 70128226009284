<template>
  <form>
      <h5 class="text-center">This table shows information about all the venues we have in the system.</h5>
      <h5 class="text-center">Please choose a venue from the table by clicking on it or add a new venue.</h5>
      <div class="row d-flex justify-content-center">
          <div class="col-md-12">
              <card title="Venues">
                  <div class="col-md-12">
                      <div class="row">
                          <pg-table :tableColumns="PgTable.tableColumns"
                                  :tableData="PgTable.tableData"
                                  :propsToSearch="PgTable.propsToSearch"
                                  :handleCurrentChange="PgTable.handleCurrentChange"
                                  :withActions="true"
                                  @on-deleteRow="deleted = true"
                                  :paginated="true"
                                  :searchable="true">
                          </pg-table>
                      </div>
                      <button type="button" class="btn btn-default btn-outline custom-button" v-on:click="addNewVenue({venueInfo: null, venueContacts: null, servicePerson: null, posCredentials: null})"> 
                          <i class="nc-icon custom-icon nc-simple-add"></i>
                          New Venue
                      </button>&nbsp;
                  </div>
              </card>  <!-- end card -->
          </div>
      </div>
  </form>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex, digits } from "vee-validate/dist/rules";
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import BootstrapVue from 'bootstrap-vue';
  import PgTable from 'src/pages/Dashboard/Tables/PaginatedTables.vue';


  extend("required", required);
  extend("email", email);
  extend("regex", regex);
  extend("digits", digits);

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      VuePhoneNumberInput,
      PgTable
    },
    data () {
      return {
        PgTable:{
          tableColumns: [
            {
                prop: 'venueName',
                label: 'Name',
                minWidth: 100
            },
            {
                prop: 'customer',
                label: 'Customer',
                minWidth: 100
            },
            {
                prop: 'posSystem',
                label: 'POS System',
                minWidth: 150
            },
            {
                prop: 'venueStatus',
                label: 'Status',
                minWidth: 100
            }
          ],
          tableData: [],
          propsToSearch: ['venueName', 'customer', 'posSystem', 'venueStatus'],
          handleCurrentChange: this.venueSelection
        },
        deleted: false
      }
    },
    props:{
      venueData: Array
    },
    mounted (){
      console.log("in venue table mounted")
      this.PgTable.tableData = this.venueData
    },
    watch: {
      venueData: {
        handler(){
          this.PgTable.tableData = this.venueData
          console.log("watch in Venue Table tab", this.venueData)
          /*this.PgTable.tableData.push({
            name: '',
            customer: '',
            posSystem: '',
            status: ''
          })
          let index = this.PgTable.tableData.length - 1
          let Vtindex = this.PgTable.tableData.length - 1 

          this.PgTable.tableData[index].name = this.venueData[Vtindex].venue.name
          this.PgTable.tableData[index].customer = this.venueData[Vtindex].customer
          this.PgTable.tableData[index].posSystem = this.venueData[Vtindex].posCredentials.posSystem
          this.PgTable.tableData[index].status = this.venueData[Vtindex].venue.venueStatus*/
        },
        deep: true
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
            this.$emit('on-validated', res)
            return res
        })
      },
      venueSelection(val) {
        if(this.deleted == false){
          this.currentRow = val;
          console.log("venue table selected row ")
          console.log(val)
          this.$emit('fireVenueLoop', val)
        }
        this.deleted = false
      },
      addNewVenue(val) {
        console.log("new venue", val)
        val.id = Math.max.apply(null, this.PgTable.tableData.map(item => item.venueInfo.id)) + 1
        this.venueSelection(val)
      }
    }
  }
</script>
<style>
.custom-button {
    font-family: Arial;
    font-size: 1.005rem;
    text-align: left;
    margin: 1;
}
.custom-icon {
   font-size: 1rem;
   font-weight: bold;
   text-align: left;
   margin: 1;
}
</style>
