<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
        <h5 class="text-center">Please enter the customer's general information.</h5>
        <div class="row d-flex justify-content-center">
            <div class="col-md-11">
                <card title="">   
                    <div class="row">
                        <div class="col-md-10">
                            <ValidationProvider
                                name="Name"
                                rules="required"
                                v-slot="{ passed, failed }"
                            >
                                <fg-input name="Name"
                                        :error="failed ? 'The Name field is required': null"
                                        :hasSuccess="passed"
                                        placeholder="Name (required)"
                                        label="Name"
                                        v-model="customerGeneralInfo.name"
                                        ddon-left-icon="nc-icon nc-single-02">
                                </fg-input>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-4">
                                    <ValidationProvider
                                        name="city"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <fg-input name="city"
                                                :error="failed ? 'The City field is required': null"
                                                :hasSuccess="passed"
                                                placeholder="City (required)"
                                                label="City"
                                                v-model="customerGeneralInfo.city">
                                        </fg-input>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-4">
                                    <ValidationProvider
                                        name="state"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <fg-input name="state"
                                                :error="failed ? 'The State field is required': null"
                                                :hasSuccess="passed"
                                                placeholder="State (required)"
                                                label="State"
                                                v-model="customerGeneralInfo.state">
                                        </fg-input>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-4">
                                    <ValidationProvider
                                        name="zipCode"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <fg-input name="zipCode"
                                                :error="failed ? 'The Zip Code field is required': null"
                                                :hasSuccess="passed"
                                                placeholder="Zip Code (required)"
                                                label="Zip Code"
                                                v-model="customerGeneralInfo.zipCode">
                                        </fg-input>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <ValidationProvider
                                    name="address"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="address"
                                            :error="failed ? 'The Address field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="Address (required)"
                                            label="Address"
                                            v-model="customerGeneralInfo.address">
                                    </fg-input>
                            </ValidationProvider>
                            <ValidationProvider
                                name="country"
                                rules="required"
                                v-slot="{ passed, failed }"
                            >
                                <fg-input name="country"
                                        :error="failed ? 'The Country field is required': null"
                                        :hasSuccess="passed"
                                        placeholder="Country (required)"
                                        label="Country"
                                        v-model="customerGeneralInfo.country">
                                </fg-input>
                            </ValidationProvider>
                            <div class="row">
                                <div class="col-md-6">
                                    <!-- 
                                    <ValidationProvider
                                        name="email"
                                        rules="required|email"
                                        v-slot="{ passed, failed }"
                                            <fg-input name="email"
                                                :error="failed ? 'The Email field is required': null"
                                                :hasSuccess="passed"
                                                placeholder="Email (required)"
                                                label="Email"
                                                v-model="email">
                                            </fg-input>
                                    </ValidationProvider>
                                    -->
                                    <fg-input name="email"
                                        placeholder="Email (optional)"
                                        label="Email"
                                        v-model="customerGeneralInfo.email">
                                    </fg-input>
                                </div>
                                <div class="col-md-6">
                                    <ValidationProvider
                                            name="status"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >
                                            <fg-input
                                                    name="customer status"
                                                    :error="failed ? 'The Customer status field is required': null"
                                                    :hasSuccess="passed"
                                                    label="Customer Status"
                                                    >
                                                    <el-select 
                                                            filterable
                                                            clearable
                                                            class="select-primary"
                                                            v-model="customerGeneralInfo.status"
                                                            >
                                                        <el-option v-for="option in statusList"
                                                            class="select-primary"
                                                            :value="option"
                                                            :label="option"
                                                            :key="option">
                                                        </el-option>
                                                    </el-select>
                                            </fg-input>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </form>
  </ValidationObserver>
</template>
<script>

  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import { Select, Option } from 'element-ui'

  extend("required", required);
  extend("email", email);

  export default {
      components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
        return {
            statusList: ['Active', 'Inactive', 'Pending']
        };
    },
    props: {
        newCustomer: false,
        customerGeneralInfo: {
            Type: Object,
            default: () => { return {
                    name: "",
                    email: "",
                    address: "",
                    country: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    status:""
                }
            }
        }
    },
    watch: {
        newCustomer: function(){
            if(this.newCustomer == true){
                this.customerGeneralInfo.name = ""
                this.customerGeneralInfo.email = ""
                this.customerGeneralInfo.address = ""
                this.customerGeneralInfo.country = ""
                this.customerGeneralInfo.city = ""
                this.customerGeneralInfo.state = ""
                this.customerGeneralInfo.zipCode = ""
                this.customerGeneralInfo.status = ""
            }
        }
    },
    methods: {
      validate() {
        //console.log("NAAANII TANIII!")
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          if(res == true){
                this.$emit('on-generalInfoSubmiting', this.customerGeneralInfo)
            }
          return res
        })
      }
    }
  }
</script>
<style>
</style>
