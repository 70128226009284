<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row d-flex justify-content-center">
        <div class="col-md-12">
          <card title="POS System">
            <div class="col-md-12">
              <div class="row">
                <!--
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="customerID"
                                    mode="passive"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input
                                            name="customerID"
                                            :error="failed ? 'The Customer field is required': null"
                                            :hasSuccess="passed"
                                            label="Customer"
                                            >
                                            <el-select
                                                filterable
                                                clearable
                                                class="select-primary"
                                                placeholder="Select Customer"
                                                v-model="POSCredentials.customerID"
                                            >
                                                <el-option
                                                    v-for="option in CustomerList"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.value"
                                                >
                                                </el-option>
                                            </el-select>
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            -->
                <div class="col-md-6">
                  <ValidationProvider
                    name="posSystem"
                    mode="passive"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="posSystem"
                      :error="
                        failed ? 'The POS System field is required' : null
                      "
                      :hasSuccess="passed"
                      label="POS System"
                    >
                      <el-select
                        filterable
                        clearable
                        class="select-primary"
                        placeholder="POS System"
                        v-model="POSCredentials.posSystem"
                      >
                        <el-option
                          v-for="option in PosSystems"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <!-- The POS systems custom cards -->
              <card
                title="InfoGenesis"
                v-show="POSCredentials.posSystem == '20'"
              >
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="InfoGenesisPath"
                      mode="passive"
                      :rules="
                        `${POSCredentials.posSystem == '20' ? 'required' : ''}`
                      "
                    >
                      <fg-input
                        id="InfoGenesisPath"
                        label="Path"
                        placeholder="Path to the folder where InfoGenesis uploades files"
                        @change="onFileChange"
                        type="text"
                        v-model="POSCredentials.IGpath"
                      >
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
              </card>
              <card
                title="Aloha"
                v-show="POSCredentials.posSystem == '4'"
              >
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="AlohaPath"
                      mode="passive"
                      :rules="
                        `${POSCredentials.posSystem == '4' ? 'required' : ''}`
                      "
                    >
                      <fg-input
                        id="AlohaPath"
                        label="Path"
                        placeholder="Path to the folder where Aloha uploades files"
                        @change="onFileChange"
                        type="text"
                        v-model="POSCredentials.IGpath"
                      >
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
              </card>
              <card
                title="Clover"
                v-show="POSCredentials.posSystem == 'Clover'"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider
                          name="cloverMerchantID"
                          mode="passive"
                          :rules="
                            `${
                              POSCredentials.posSystem == 'Clover'
                                ? 'required'
                                : ''
                            }`
                          "
                        >
                          <fg-input
                            id="cloverMerchantID"
                            label="Merchant ID"
                            placeholder="Merchant ID"
                            @change="onFileChange"
                            type="text"
                            v-model="POSCredentials.ClMerchantID"
                          >
                          </fg-input>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider
                          name="cloverAccessToken"
                          mode="passive"
                          :rules="
                            `${
                              POSCredentials.posSystem == 'Clover'
                                ? 'required'
                                : ''
                            }`
                          "

                        >
                          <fg-input
                            id="cloverAccessToken"
                            label="Access Token"
                            placeholder="Access Token"
                            @change="onFileChange"
                            type="text"
                            v-model="POSCredentials.ClAccessToken"
                          >
                          </fg-input>
                        </ValidationProvider>
                      </div>
                    </div>
                    <ValidationProvider
                      name="cloverHost"
                      mode="passive"
                      :rules="
                        `${
                          POSCredentials.posSystem == 'Clover' ? 'required' : ''
                        }`
                      "
                    >
                      <fg-input
                        id="cloverHost"
                        label="Host"
                        placeholder="Host"
                        @change="onFileChange"
                        type="text"
                        v-model="POSCredentials.ClHost"
                      >
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
              </card>
              <card
                title="Shift4 Payments"
                v-show="POSCredentials.posSystem == 'Shift4'"
              >
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      name="shift4ClientID"
                      mode="passive"
                      :rules="
                        `${
                          POSCredentials.posSystem == 'Shift4' ? 'required' : ''
                        }`
                      "
                    >
                      <fg-input
                        id="shift4ClientID"
                        label="Client ID"
                        placeholder="Client ID"
                        @change="onFileChange"
                        type="text"
                        v-model="POSCredentials.Sh4ClientID"
                      >
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      name="shift4ClientID"
                      mode="passive"
                      :rules="
                        `${
                          POSCredentials.posSystem == 'Shift4' ? 'required' : ''
                        }`
                      "
                    >
                      <fg-input
                        id="shift4ClientSecret"
                        label="Client Secret"
                        placeholder="Client Secret"
                        @change="onFileChange"
                        type="text"
                        v-model="POSCredentials.Sh4ClientSecret"
                      >
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
              </card>
              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider
                    name="importFrequency"
                    mode="passive"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="importFrequency"
                      :error="
                        failed ? 'The Import Frequency field is required' : null
                      "
                      :hasSuccess="passed"
                      label="Import Frequency"
                    >
                      <el-select
                        filterable
                        clearable
                        class="select-primary"
                        placeholder="Import Frequency (per day)"
                        v-model="POSCredentials.importFrequency"
                      >
                        <el-option
                          v-for="option in ImportFrequencyList"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider
                    name="reportFrequency"
                    mode="passive"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="reportFrequency"
                      :error="
                        failed ? 'The Report Frequency field is required' : null
                      "
                      :hasSuccess="passed"
                      label="Report Frequency"
                    >
                      <el-select
                        filterable
                        clearable
                        class="select-primary"
                        placeholder="Report Frequency (per day)"
                        v-model="POSCredentials.reportFrequency"
                      >
                        <el-option
                          v-for="option in ReportFrequencyList"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </card>
          <!-- end card -->
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { Select, Option } from "element-ui";
import { extend } from "vee-validate";
import { required, email, regex, digits } from "vee-validate/dist/rules";
import { initAPI } from "src/gateway/api-instance.js";

extend("required", required);
extend("email", email);
extend("regex", regex);
extend("digits", digits);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      PosSystems: [
        { value: "", label: "- POS System -" }
      ],
      CustomerList: [
        { value: "", label: "- Customer -" }
      ],
      ImportFrequencyList: [
        { value: "", label: "- Import Frequency (per day) -" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" },
        { value: "24", label: "24" }
      ],
      ReportFrequencyList: [
        { value: "", label: "- Report Frequency (per day) -" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" },
        { value: "24", label: "24" }
      ],
      POSCredentials: {
        posSystem: "",
        IGpath: "",
        ClMerchantID: "",
        ClAccessToken: "",
        ClHost: "",
        Sh4ClientID: "",
        Sh4ClientSecret: "",
        importFrequency: "",
        reportFrequency: ""
      }
    };
  },
  props: {
    currentVenuePOS: Object
  },
  mounted() {
    this.getPOSSystemsList();
    this.POSCredentials = this.currentVenuePOS;
  },
  watch: {
    /*
      - To put a variable in the watch clause, means that it's handler function will be callled whenever a change occurrs in it's data.
      - Attribute deep, means that the watched variable may be a nested variable, and the clause should watch for changes in the deeper level also.
    */
    currentVenuePOS: {
      handler() {
        this.POSCredentials = this.currentVenuePOS;
      },
      deep: true
    }
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Calls a GET API to get a list of POS systems from the database.
      - Fills the drop down ist with the retrieved data.
    */
    getPOSSystemsList() {
      this.Instance.get("/simplica/pos_systems/").then(response => {
        response.data.forEach((element, index) => {
          this.PosSystems.push({
            value: element.ID,
            label: element.DESCRIPTION
          });
        });
      });
    },
    onFileChange: function(e) {
      console.log(e);
    },
    validate() {
      /*
        - Called when a button with class "submit" is clicked.
        - Goes through every component within a 'validationProvider' tag, and validates all it's rules.
        - When validations succeed, emits a function trigger in the parent component with the form data.
      */
      return this.$refs.form.validate().then(res => {
        this.$emit("on-validated", res);
        if (res === true) {
          this.$emit("on-POSCredSubmiting", this.POSCredentials);
        }
        return res;
      });
    }
  }
};
</script>
<style>
.custom-button {
  font-family: Arial;
  font-size: 1.005rem;
  text-align: left;
  margin: 1;
}
.custom-icon {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin: 1;
}
</style>
