<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
        <h5 class="text-center"> Please enter the Venue's contacts information.</h5>
        <div class="row d-flex justify-content-center" v-for="(contact, id) in VenueContacts" v-bind:key="id">
            <div class="col-md-11" v-show="id > 0">
                <button class="btn btn-social custom-button btn-round btn-link" v-on:click="removeContact(id)">
                    <i class="fa fa-times-circle"></i>
                </button>
                <br>
            </div>
            <div class="col-md-11">
                <card title="Venue Contact">
                    <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="firstName"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="firstName"
                                            :error="failed ? 'The First Name field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="First Name (required)"
                                            label="First Name"
                                            v-model="contact.firstName"
                                            ddon-left-icon="nc-icon nc-single-02">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="lastName"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="lastName"
                                            :error="failed ? 'The Last Name field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="Last Name (required)"
                                            label="Last Name"
                                            v-model="contact.lastName"
                                            ddon-left-icon="nc-icon nc-single-02">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="email"
                                    rules="required|email"
                                    v-slot="{ passed, failed }"
                                    >
                                        <fg-input name="email"
                                            :error="failed ? 'The Email field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="Email (required)"
                                            label="Email"
                                            v-model="contact.email">
                                        </fg-input>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <fg-input
                                        name="customer phone"
                                        label="Phone Number"
                                        >
                                    <vue-tel-input autocomplete="on"
                                                    v-model="contact.phoneNumber"
                                                    mode="international"
                                                    :autoDefaultCountry='false'>
                                    </vue-tel-input>
                                </fg-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">      
                                <ValidationProvider
                                    name="Type"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input
                                            name="contactType"
                                            :error="failed ? 'The Contact Type field is required': null"
                                            :hasSuccess="passed"
                                            label="Contact Type"
                                            >
                                            <el-select 
                                                    filterable
                                                    clearable
                                                    class="select-primary"
                                                    v-model="contact.contactType"
                                                    >
                                                <el-option v-for="option in ContactType"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.value">
                                                </el-option>
                                            </el-select>
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            <!--
                            <div class="col-md-6">      
                                <ValidationProvider
                                    name="VenueID"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input
                                            name="venueID"
                                            :error="failed ? 'The Venue ID field is required': null"
                                            :hasSuccess="passed"
                                            label="Venue ID"
                                            >
                                            <el-select 
                                                    filterable
                                                    clearable
                                                    class="select-primary"
                                                    v-model="contact.venueID"
                                                    >
                                                <el-option v-for="option in VenueID"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.value">
                                                </el-option>
                                            </el-select>
                                    </fg-input>
                                </ValidationProvider>
                            </div> -->
                        </div>
                    </div>
                </card>  <!-- end card -->
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-11">
                <button type="button" class="btn btn-default btn-outline custom-button" v-on:click="addContact"> 
                    <i class="nc-icon custom-icon nc-simple-add"></i>
                    New Contact
                </button>&nbsp;
            </div>
        </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex, digits } from "vee-validate/dist/rules";
  import Vue from 'vue'
  import VueTelInput from 'vue-tel-input'
  import 'vue-tel-input/dist/vue-tel-input.css'

  Vue.use(VueTelInput)


  extend("required", required);
  extend("email", email);
  extend("regex", regex);
  extend("digits", digits);

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data () {
      return {
        ContactType: [
          {value: '', label: '- Contact Type -'},
          {value: 'admin', label: 'Venue admin'},
          {value: 'owner', label: 'Venue owner'}],
        VenueID: [
          {value: '', label: '- Venue ID -'},
          {value: '1', label: '1'},
          {value: '2', label: '2'},
          {value: '3', label: '3'},
          {value: '4', label: '4'},
          {value: '5', label: '5'},
          {value: '6', label: '6'}],
        VenueContacts: [{
            firstName:'',
            lastName:'',
            phoneNumber:'',
            email:'',
            contactType: '',
            venueID:'',
        }],
        numContacts: 0
      }
    },
    props: {
        currentVenueContacts: Array
    },
    mounted(){
        console.log("mounted venue contacts", this.currentVenueContacts)
        if(this.currentVenueContacts != null){
            this.VenueContacts = this.currentVenueContacts
            this.numContacts = this.VenueContacts.length
            console.log(this.currentVenueContacts[0].phoneNumber)
        }
    },
    watch: {
        currentVenueContacts: {
            handler(){
                console.log("watch venue contacts")
                if(this.currentVenueContacts != null){
                    console.log(this.currentVenueContacts.venueContacts)
                    this.VenueContacts= this.currentVenueContacts
                    this.numContacts = this.VenueContacts.length
                }
                else{
                    this.VenueContacts = [{
                        firstName:'',
                        lastName:'',
                        phoneNumber:'',
                        email:'',
                        contactType: '',
                        venueID:'',
                    }]
                }
            },
            deep: true
        }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
            //console.log("hmm")
            //console.log(this.VenueContacts);
            this.$emit('on-validated', res)
            if(res == true){
                this.$emit("on-venueContactSubmitting", this.VenueContacts)
            }
            return res
        })
      },
      addContact(){
          //console.log("here " + this.numContacts)
          this.numContacts++;
          this.VenueContacts.push({
            firstName:'',
            lastName:'',
            phoneNumber:'',
            email:'',
            contactType: '',
            venueID:'',
        });
      },
      removeContact(index){
          //console.log("Naaanii!!")
          //console.log(this.numContacts)
          if(this.numContacts > 0){
            this.numContacts--;
            this.VenueContacts.splice(index, 1);
          }
      }
    }
  }
</script>
<style>
.custom-button {
    font-family: Arial;
    float: right;
    font-size: 1.005rem;
    text-align: right;
    margin: 0.005px;
    cursor: pointer;
}
.custom-icon {
   font-size: 1rem;
   font-weight: bold;
   text-align: right;
   margin: 1;
}
</style>
