<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
        <h5 class="text-center">Please enter the Venues' information.</h5>
        <div class="row d-flex justify-content-center">
            <div class="col-md-11">
                <card title="Venue">
                    <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="firstName"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="Name"
                                            :error="failed ? 'The Name field is required': null"
                                            :hasSuccess="passed"
                                            placeholder= "Name (required)"
                                            label="Name"
                                            v-model="venue.name"
                                            ddon-left-icon="nc-icon nc-single-02">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="Type"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input
                                            name="venueType"
                                            :error="failed ? 'The Venue Type field is required': null"
                                            :hasSuccess="passed"
                                            label="Venue Type"
                                            >
                                            <el-select 
                                                    filterable
                                                    clearable
                                                    class="select-primary"
                                                    v-model="venue.venueType"
                                                    >
                                                <el-option v-for="option in VenueType"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.value">
                                                </el-option>
                                            </el-select>
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                        </div>
                        <PosCredentials :currentVenuePOS='VenuePOSCredentials' ref='POSCredentialsForm' @on-POSCredSubmiting="getPOSData"></PosCredentials>
                        <div class="row">
                            <div class="col-md-4">
                                <ValidationProvider
                                    name="city"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="city"
                                            :error="failed ? 'The City field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="City (required)"
                                            label="City"
                                            v-model="venue.city">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                                <ValidationProvider
                                    name="state"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="state"
                                            :error="failed ? 'The State field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="State (required)"
                                            label="State"
                                            v-model="venue.state">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                                <ValidationProvider
                                    name="zipCode"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input name="zipCode"
                                            :error="failed ? 'The Zip Code field is required': null"
                                            :hasSuccess="passed"
                                            placeholder="Zip Code (required)"
                                            label="Zip Code"
                                            v-model="venue.zipCode">
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                        </div>
                        <ValidationProvider
                                name="address"
                                rules="required"
                                v-slot="{ passed, failed }"
                            >
                                <fg-input name="address"
                                        :error="failed ? 'The Address field is required': null"
                                        :hasSuccess="passed"
                                        placeholder="Address (required)"
                                        label="Address"
                                        v-model="venue.address">
                                </fg-input>
                        </ValidationProvider>
                        <ValidationProvider
                            name="country"
                            rules="required"
                            v-slot="{ passed, failed }"
                        >
                            <fg-input name="country"
                                    :error="failed ? 'The Country field is required': null"
                                    :hasSuccess="passed"
                                    placeholder="Country (required)"
                                    label="Country"
                                    v-model="venue.country">
                            </fg-input>
                        </ValidationProvider>
                        <!--
                        <div class="row">
                            <div class="col-md-6">      
                                <ValidationProvider
                                    name="contactID"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input
                                            name="contactID"
                                            :error="failed ? 'The Contact field is required': null"
                                            :hasSuccess="passed"
                                            label="Contact"
                                            >
                                            <el-select 
                                                    filterable
                                                    clearable
                                                    class="select-primary"
                                                    v-model="venue.primaryContactID"
                                                    >
                                                <el-option v-for="option in PrimaryContactID"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.value">
                                                </el-option>
                                            </el-select>
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">      
                                <ValidationProvider
                                    name="serviceContactID"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input
                                            name="serviceContactID"
                                            :error="failed ? 'The Service Contact field is required': null"
                                            :hasSuccess="passed"
                                            label="Service Contact"
                                            >
                                            <el-select 
                                                    filterable
                                                    clearable
                                                    class="select-primary"
                                                    v-model="venue.servicePersonID"
                                                    >
                                                <el-option v-for="option in ServicePersonID"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.value">
                                                </el-option>
                                            </el-select>
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                        </div>
                        -->
                        <div class="row">
                            <div class="col-md-6">
                                <fg-input name="email"
                                    placeholder= "Email (optional)"
                                    label="Email"
                                    v-model="venue.email">
                                </fg-input>
                            </div>
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="status"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                >
                                    <fg-input
                                            name="venueStatus"
                                            :error="failed ? 'The Venue status field is required': null"
                                            :hasSuccess="passed"
                                            label="Venue Status"
                                            >
                                            <el-select 
                                                    filterable
                                                    clearable
                                                    class="select-primary"
                                                    v-model="venue.venueStatus"
                                                    >
                                                <el-option v-for="option in statusList"
                                                    class="select-primary"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="option.value">
                                                </el-option>
                                            </el-select>
                                    </fg-input>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </card>  <!-- end card -->
            </div>
        </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex, digits } from "vee-validate/dist/rules";
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import BootstrapVue from 'bootstrap-vue';
  import PosCredentials from 'src/pages/Dashboard/Forms/Wizard/CustomerOnboardingWizard/CustomerVenuesWizard/CustomerPosCredentialsCard.vue';


  extend("required", required);
  extend("email", email);
  extend("regex", regex);
  extend("digits", digits);

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      VuePhoneNumberInput,
      PosCredentials
    },
    data () {
      return {
        PrimaryContactID: [
          {value: '', label: '- Primary Contact -'},
          {value: '1', label: '1'},
          {value: '2', label: '2'},
          {value: '3', label: '3'},
          {value: '4', label: '4'},
          {value: '5', label: '5'},
          {value: '6', label: '6'}],
        ServicePersonID: [
          {value: '', label: '- Service Person -'},
          {value: '1', label: '1'},
          {value: '2', label: '2'},
          {value: '3', label: '3'},
          {value: '4', label: '4'},
          {value: '5', label: '5'},
          {value: '6', label: '6'}],
        VenueType: [
          {value: '', label: '- Venue Type -'},
          {value: 'main', label: 'Main Venue'},
          {value: 'subVenue', label: 'Sub Venue'}],
        statusList: [{value: '', label: '- Venue Status -'},
          {value: 'A', label: 'Active'},
          {value: 'I', label: 'Inactive'},
          {value: 'P', label: 'Pending'}],
        venue: {
            name:'',
            email:"",
            address: "",
            country: "",
            city: "",
            state: "",
            zipCode: "",
            timeZone: "",
            venueType: "",
            venueStatus: ""
        },
        VenuePOSCredentials: {
            posSystem: "",
            IGpath: "",
            ClMerchantID: "",
            ClAccessToken: "",
            ClHost: "",
            Sh4ClientID: "",
            Sh4ClientSecret: "",
            importFrequency: "",
            reportFrequency: ""
        }
      }
    },
    props:{
        currentVenue: Object
    },
    mounted(){
        if(this.currentVenue.venueInfo != null){
            this.venue = this.currentVenue.venueInfo
            this.VenuePOSCredentials = this.currentVenue.posCredentials
            console.log("in, venue info mounted", this.currentVenue)
        }
        else{
            console.log("null correct in info")
            this.venue.name = ""
            this.venue.venueStatus = ""
            this.VenuePOSCredentials.posSystem = ""
        }
    },
    watch: {
        currentVenue: {
            handler(){
                if(this.currentVenue.venueInfo != null){
                    this.venue = this.currentVenue.venueInfo
                    this.VenuePOSCredentials = this.currentVenue.posCredentials
                    console.log("watch in venue info ", this.currentVenue)
                }
                else{
                    this.venue.name = ""
                    this.venue.venueStatus = ""
                    this.VenuePOSCredentials.posSystem = ""
                }
            },
            deep: true
        }
    },
    methods: {
      getPOSData(POSCredentials){
          this.VenuePOSCredentials = POSCredentials;
      },
      validate() {
          return this.$refs.form.validate().then(res => {
            this.$emit('on-validated', res)
            if(res == true){
                // thats how to get the POS credentials cards' values
                //console.log(this.$refs.POSCredentialsForm.POSCredentials)
                let Id = 0;
                if(this.currentVenue.id != null){
                    Id = this.currentVenue.id;
                }
                console.log("in venue info, id", Id)
                //this.$emit('on-VenueSubmiting', this.$refs.POSCredentialsForm.POSCredentials, this.venue, Id)
                this.$emit('on-VenueSubmiting', this.VenuePOSCredentials, this.venue, Id)
            }
            console.log("here validate venue info ", res)
            return res
        })
      },
    }
  }
</script>
<style>
.custom-button {
    font-family: Arial;
    font-size: 1.005rem;
    text-align: left;
    margin: 1;
}
.custom-icon {
   font-size: 1rem;
   font-weight: bold;
   text-align: left;
   margin: 1;
}
</style>
